import "./App.css";
import Link from "./pages/link";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import TermsOfService from "./components/terms-of-service";
import PrivacyPolicy from "./components/privacy-policy";
import DataDeletionPage from "./components/data-delete";

function App() {
  return (
    <div className="App min-h-full overflow-x-hidden z-0">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="link/:type" element={<Link />} />
        <Route path="link/:type/:id" element={<Link />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="data-deletion" element={<DataDeletionPage />} />
      </Routes>
    </div>
  );
}

export default App;
