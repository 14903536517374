import React from "react";
import { IoLogoApple, IoLogoGooglePlaystore } from "react-icons/io5";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../constants";

function Download() {
  return (
    <div className="py-3.5 md:py-10 px-3.5 lg:px-10 bg-white rounded-3xl">
      <div className="mx-auto w-full max-w-screen-xl h-auto px-2.5 gap-20 md:px-10 lg:px-20 flex flex-col md:flex-row items-center">
        <div className="flex flex-col justify-start gap-5">
          <h1 className="capitalize font-extrabold text-secondary font-lato text-2xl md:text-3xl lg:text-4xl">
            Download CityMoshi now
          </h1>
          <p className="text-grayLight text-xs md:text-lg font-medium leading-5 md:leading-8">
            Discover, Plan, and Experience Unforgettable Events! Join Now to
            Create Lasting Memories! Download CityMoshi - Your choice, your
            vibe!
          </p>
        </div>
        <div className="w-fit grow flex flex-row md:flex-col gap-5">
          <button
            className="basis-1 grow h-full bg-secondary flex items-center gap-1 md:gap-3 cursor-pointer text-white py-2.5 md:py-3 px-1 md:px-4 rounded-lg outline-none m-0 p-0"
            onClick={() => window.open(APP_STORE_LINK, "_blank")}
          >
            <IoLogoApple className="w-8 h-8 text-white" />
            <div className="flex flex-col justify-start items-start md:leading-3 text-start">
              <p className="text-nowrap  text-white text-xs m-0 p-0">Download on the</p>
              <p className="text-nowrap text-white text-xs md:text-base font-medium m-0 p-0 leading-3">
                App Store
              </p>
            </div>
          </button>
          <button
            className="basis-1 grow bg-secondary cursor-pointer flex items-center gap-1 md:gap-3 text-white py-2.5 md:py-3 px-1 md:px-4 rounded-lg outline-none m-0 p-0"
            onClick={() => window.open(GOOGLE_PLAY_LINK, "_blank")}
          >
            <IoLogoGooglePlaystore className="w-8 h-8 text-white" />
            <div className="flex flex-col justify-start items-start md:leading-3 text-start">
              <p className="text-nowrap text-white text-xs m-0 p-0">Download it on</p>
              <p className="text-nowrap text-white text-xs md:text-base font-medium m-0 leading-3">
                Google Play
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Download;
