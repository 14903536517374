import { IoLogoGooglePlaystore, IoLogoApple } from "react-icons/io5";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../constants";


function Welcome() {
  return (
    <div
      className="w-full relative overflow-hidden min-h-auto flex items-start mt-32 md:mt-10"
      id="welcome"
    >
      <div className="hidden md:block w-full absolute -top-24 left-0" style={{zIndex: 0}}>
        <img
          src="/images/group.png"
          alt="city"
          className="w-full h-full rounded-lg object-cover"
        />
      </div>
      <div className="w-full h-full flex flex-col justify-center gap-[30px] z-60" style={{zIndex: 1}}>
        <div className="flex flex-col items-center gap-[30px] md:gap-3">
          <h1 className="text-white text-[28px] md:text-5xl px-5 leading-8 font-medium font-inter text-center">
            Your Guide To Local Happenings!
          </h1>
          <p className="text-white md:text-grayLight max-w-sm px-5 md:px-12 md:max-w-2xl font-inter mt-3 text-center">
            Exclusive, uniquely personalized, vibrant local events and hotspots
            you’ll love.
          </p>
        </div>
        <div className="w-full flex items-center justify-center gap-2.5 md:gap-10">
          <button 
            className="bg-secondary flex items-center gap-3 cursor-pointer text-white py-3 px-4 rounded-lg outline-none"
            onClick={() => window.open(APP_STORE_LINK, "_blank")}
          >
            <IoLogoApple className="w-8 h-8 text-white" />
            <div className="flex flex-col justify-center items-start leading-3">
              <p className="text-white text-xs m-0">Download on the</p>
              <p className="text-white text-sm md:text-base font-medium m-0 leading-3">
                App Store
              </p>
            </div>
          </button>
          <button
            className="bg-secondary flex items-center gap-3 cursor-pointer text-white py-3 px-4 rounded-lg outline-none"
            onClick={() => window.open(GOOGLE_PLAY_LINK, "_blank")}
          >
            <IoLogoGooglePlaystore className="w-8 h-8 text-white" />
            <div className="flex flex-col justify-center leading-0 items-start">
              <p className="text-white text-[10px] m-0 p-0 leading-3">
                Download it on
              </p>
              <p className="text-white text-sm md:text-base m-0 p-0 font-medium leading-3">
                Google Play
              </p>
            </div>
          </button>
        </div>

        <div className="w-full h-auto flex items-center justify-center">
          <img
            src="/images/poster-big.png"
            alt="device sample"
            className="max-w-4xl w-full h-full rounded-lg object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
