import { MdOutlinePhotoCamera, MdOutlineSignalCellularAlt2Bar } from "react-icons/md";
import { FaRegHandshake } from "react-icons/fa";
function About() {
  return (
    <div
      id="about"
      className="mx-auto w-full max-w-screen-xl px-6 md:px-20 flex flex-col items-center justify-center gap-[70px] mt-16 pb-10"
    >
      <div className="flex flex-col justify-center gap-5 items-center">
        <h1 className="font-extrabold text-white font-lato text-[28px] md:text-[40px]">
          What is CityMoshi?
        </h1>
        <p className="max-w-xl text-sm md:text-base leading-7 text-center text-white md:text-[#94A3B8]">
          <b>City</b> for us means any place, from big cities to small villages.{" "}
          <b>Moshi</b> is a word from the Swahili language that means smoke, a
          signal that something is happening. CityMoshi helps you find events
          and social gatherings you like, no matter where you are. With
          CityMoshi, you can also create your own private events. Stay connected
          and never miss out with CityMoshi!
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 w-full h-auto">
        <div className="w-full h-auto rounded-3xl p-4 flex flex-col justify-start bg-gray">
          <div className="w-full max-w-max bg-white rounded-full mb-5 p-5 flex items-center gap-3">
            {/* <img
              src="/images/feature1.png"
              alt="feature1"
              className="w-8 h-8 object-cover"
            /> */}
            <div className="bg-BG rounded-full p-2">
              <MdOutlineSignalCellularAlt2Bar className="w-8 h-8 text-white" />
            </div>
            <h3 className="text-BG font-medium capitalize text-2xl">
              Navigator
            </h3>
          </div>
          <p className="text text-[#94A3B8] leading-8">
            We live in a vibrant city where exciting events are happening all
            the time, yet we often miss out because we don’t know where and when
            they happen until we see them in the news. Sometimes, we rely on our
            friends who are more in-the-know, or scroll through countless social
            media posts in hopes of finding something interesting, but more
            often than not, we come up empty-handed.
          </p>
        </div>
        <div className="w-full h-auto rounded-3xl p-4 px-6 flex flex-col justify-start bg-[#A6425C]">
          <div className="w-full max-w-max bg-BG rounded-full mb-5 p-5 flex items-center gap-3">
            {/* <img
              src="/images/feature2.png"
              alt="feature1"
              className="w-8 h-8 object-cover"
            /> */}
            <div className="bg-white rounded-full p-2">
              <FaRegHandshake className="w-8 h-8 text-BG" />
            </div>
            <h3 className="text-secondary font-medium capitalize text-2xl">
              Feedback
            </h3>
          </div>
          <p className="text text-BG leading-8 font-medium">
            Sometimes, when we go to events, we want to share our thoughts,
            either to praise the organisers or suggest improvements. But it's
            often hard to find a direct way to talk to them. We create online or
            offline groups to discuss the matter, without knowing if our message
            will reach them. It would be great to have a simple platform to make
            sure our feedback gets heard.
          </p>
        </div>
        <div className="w-full h-auto rounded-3xl p-4 flex flex-col justify-start bg-gray">
          <div className="w-full max-w-max bg-white rounded-full mb-5 p-5 flex items-center gap-3">
            {/* <img
              src="/images/feature1.png"
              alt="feature1"
              className="w-8 h-8 object-cover"
            /> */}
            {/* Camera icon */}
            <div className="bg-BG rounded-full p-2">
              <MdOutlinePhotoCamera className="w-8 h-8 text-white" />
            </div>
            <h3 className="text-BG font-medium capitalize text-2xl">
              Memories
            </h3>
          </div>
          <p className="text-sm leading-8-sm text-[#94A3B8] leading-8">
            We often host private gatherings with loved ones, creating cherished
            memories we want to remember forever. Despite our efforts forming
            social media groups, snapping scattered photos, events pass by,
            groups get deleted, and memories blur amidst a sea of unrelated
            posts. I wish there was a single platform where memories from these
            special occasions can be securely stored and easily revisited,
            keeping our shared experiences alive and easy to find.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
