function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md m-6 text-gray">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-8">Effective: 27 February 2024</p>

      <section className="mb-8">
        <p className="mb-4">
          CityMoshi is a platform dedicated to helping users discover and attend
          a wide array of physical get-togethers, meet-ups, and events around
          them. Our mission is to promote tourism and hospitality by connecting
          people with live, in-person experiences in various locations
          worldwide. Through our platform, we empower users to explore, create,
          and participate in events and rendezvous that enhance their social
          interactions and cultural experiences, fostering a sense of community
          and adventure across the globe.
        </p>
        <p className="mb-4">
          Our services allow organizers to post events, respond to client
          inquiries, and interact directly with users. Users can explore
          activities based on their location and preferences, create private
          social events with their circles, and store and share memories through
          photos and videos.
        </p>
        <p className="mb-4">
          When this Privacy Policy uses the term "Organizer," it refers to any
          public or private entity using our Services to create public
          gatherings for individual participants. These participants may use our
          Services (a) to obtain information about or attend gatherings
          ("Participants"), or (b) for any other purpose. Organizers,
          participants, and third parties utilizing our Services are
          collectively referred to in these Terms as "Users," "you," or
          "your."CityMoshi is a private company with its principal place of
          business at Kigali, Rwanda, and with the company code 122398439
          ("CityMoshi," "us," "we," or "our"). For individuals residing outside
          Rwanda, CityMoshi Rwanda is the responsible party concerning Personal
          Data collected through our services. If you have any questions or
          concerns at any time, please do not hesitate to contact us at the
          address above or by contacting us at privacy@citymoshi.com.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Applicability of This Privacy Policy
        </h2>
        <p className="mb-4">
          We take the privacy of your Personal Data seriously, which is why we
          have established this Privacy Policy. Please read it carefully, as it
          contains important information regarding your Personal Data and other
          relevant details. This Privacy Policy outlines our approach to
          handling information that can be associated with or used to identify
          an individual ("Personal Data") collected from users through our
          services.
        </p>
        <p className="mb-4">
          "Non-Personal Data," as referred to in this Privacy Policy, is any
          information that does not relate to or cannot be used to identify an
          individual. When you interact with our services, we may collect
          Non-Personal Data. The limitations and requirements on the collection,
          use, disclosure, transfer, and storage/retention of Personal Data
          outlined in this Privacy Policy do not apply to Non-Personal Data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <p className="mb-4">
          We've organized this into several categories: information you provide
          to us, information we generate based on your use of our Services, and
          information we receive from others. Occasionally, with your
          permission, we may also collect additional information.
        </p>

        <ul className="list-disc pl-6 mb-6 text-gray">
          <li className="mb-2">
            <strong>Information You Provide to Us:</strong> This includes data
            you directly give us when you use our Services, such as when you
            create an account, fill out forms, or communicate with us.
          </li>
          <li className="mb-2">
            <strong>Information We Generate:</strong> When you use our Services,
            we automatically generate information about your usage patterns and
            preferences. This can include data such as your interaction with the
            platform, preferences, and other usage statistics.
          </li>
          <li className="mb-2">
            <strong>Information You Provide to Us:</strong> Sometimes, we
            receive information about you from third parties. This can include
            data from other services you use or interact with that are
            integrated with our platform.
          </li>
        </ul>
        <p>
          In some cases, we may ask for your explicit permission to collect
          additional information to enhance your experience with our Services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information you provide</h2>
        <p className="mb-4">
          To use many of our Services, you are required to set up an account.
          During this process, we collect account details and other Personal
          Data that you voluntarily provide, such as when you register, contact
          us with inquiries, respond to surveys, participating in an event, or
          use specific parts of our Services. This Personal Data may include,
          but is not limited to, your name, address, email address, birthday,
          phone number, content preferences, and any other information you
          choose to provide that enables personal identification.
        </p>
        <p className="mb-4">
          <strong>For Organizers</strong>, we collect additional Personal Data,
          including company registration details, credit card information for
          payments, financial details for payment processing, and information
          required for tax purposes. When creating a get-together or an event,
          we may also verify your account via phone to prevent fraud. We may
          receive additional contact information from third-party sources like
          banks, marketing partners, and credit reporting agencies.
        </p>
        <p className="mb-4">
          <strong>Participants (users) </strong>, may provide additional
          Personal Data, either for our purposes or on behalf of an Organizer.
          Although we do not directly register participants, Organizers can
          collect information through event registration pages. We do not
          control the registration process or the Personal Data collected by
          Organizers.
        </p>

        <p className="mb-4">
          <strong>Additionally</strong>, we collect and consider as private
          content the information you send through or save within our Services,
          such as chats with your circle and private events. Public content,
          such as public chats, photos and profiles, can be viewed and saved by
          others. We advise you to share information mindfully.
        </p>
        <p className="mb-4">
          When you contact our support department or communicate with us in any
          other capacity, including research efforts like surveys or consumer
          panels, we collect the necessary information to resolve your
          inquiries. If you engage with our sales team, we may collect your
          professional contact details and, with your consent, audio or video
          recordings of meetings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information We Generate</h2>
        <p className="mb-4">
          When you use our Services, we collect information about which Services
          you use and how you use them. This helps us understand how our
          community engages with our platform and guides us in making
          improvements. We gather usage information on how you interact with our
          Services, such as which events you view, react to, and save and your
          communication frequency with other users. This includes data on your
          interactions with our platform, which helps us tailor the Services to
          better meet your needs.
        </p>
        <p className="mb-4">
          We collect <strong>device information</strong> about your device, such
          as hardware or software details, operating system, device memory,
          browser type, sensor information, and connectivity details like
          wireless and mobile connections. If you've granted permissions, this
          may also include data from your device’s phonebook, camera, photos,
          and microphone. This helps us optimize our Services for different
          devices and improve functionality.
        </p>
        <p className="mb-4">
          We gather <strong>location information</strong>, such as your IP
          address, and, if allowed, precise location details through GPS
          signals. This allows us to provide location-based services and
          recommendations.
        </p>
        <p className="mb-4">
          <strong>Cookies and similar technologies</strong> are used to collect
          information based on your settings, such as browsing behavior and
          preferences. This helps us enhance your user experience by
          personalizing content and remembering your preferences.
        </p>
        <p className="mb-4">
          <strong>Log information</strong> includes details about your use of
          our Services, access times, pages viewed, IP addresses, and unique
          identifiers like cookies. By collecting and analyzing this
          information, we aim to enhance user experience, improve our Services,
          and ensure the platform remains user-friendly and efficient.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Data We Receive From Others
        </h2>
        <p className="mb-4">
          The last category of data we collect includes information about you
          that we may receive from others, such as other users, our affiliates,
          and third parties. This encompasses linked third-party service data,
          which is information we obtain when you link your CityMoshi account to
          another service.
        </p>
        <p className="mb-4">
          We also collect data from advertisers and contact information from
          other CityMoshi users or third parties. For instance, if another
          CityMoshi user uploads their contact list that includes your
          information, we may combine that with other information we have about
          you to better understand who you might want to be in your circle.
          Similarly, if you provide us with your contact information, we may use
          it to determine if we can reach you through other communication
          methods, such as SMS, email, or other messaging services.
        </p>
        <p className="mb-4">
          Additionally, we may receive data related to potential violations of
          our Terms of Service and Community Guidelines. This information can
          come from various sources, including website publishers, social
          network providers, law enforcement, and others, to help us identify
          and address any potential issues or breaches.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How we use information</h2>
        <p className="mb-4">
          We use the information we collect, subject to your choices, to provide
          and support the CityMoshi services as described in the CityMoshi
          Terms. Here's how:
        </p>
        <ol className="list-decimal ml-6 mb-4">
          <li className="mb-2">
            <strong>Provide, Personalize, and Improve Our Products:</strong> We
            use the information we have to deliver our services, including
            personalizing features and content, and making suggestions for you
            (such as events you may be interested in). To create personalized
            experiences that are unique and relevant to you, we consider your
            connections, preferences, interests, and activities based on data we
            collect from you and others. This includes how you use and interact
            with our services and the people, places, or things you're
            interested in, both on and off our Products.
          </li>
          <li className="mb-2">
            <strong>Location-Related Information:</strong> We use location data,
            such as your current location, places you frequent, and nearby
            events, to personalize and improve our services, including ads, for
            you and others. This data can be derived from precise device
            location (if allowed), IP addresses, and your interactions with
            CityMoshi services.
          </li>
          <li className="mb-2">
            <strong>Product Research and Development:</strong> We use the
            information we have to develop, test, and improve our Services
            through surveys, research, testing, and troubleshooting new
            features.
          </li>
          <li className="mb-2">
            <strong>Ads and Other Sponsored Content:</strong> We use information
            about your interests, actions, and circle to select and personalize
            ads, offers, and other sponsored content that we show you.
          </li>
          <li className="mb-2">
            <strong>Analytics:</strong> We conduct analytics to identify,
            monitor, and analyze trends and usage patterns. This helps us create
            insights about our users and understand demand better.
          </li>
          <li className="mb-2">
            <strong>Enhance Safety & Security:</strong> We use your information
            to improve the safety and security of our services. This includes
            verifying user identities and preventing fraud or other unauthorized
            or illegal activities.
          </li>
          <li className="mb-2">
            <strong>Contacting You:</strong> We may contact you to promote new
            or existing features.
          </li>
          <li className="mb-2">
            <strong>Support:</strong> When you request assistance, we aim to
            provide support as quickly as possible. We use the information we
            have collected to respond effectively to your issues with our
            services.
          </li>
          <li className="mb-2">
            <strong>Enforce Our Terms & Policies:</strong> We use the data we
            collect to uphold our Terms and comply with legal requirements. This
            includes investigating, reporting, and addressing conduct that
            violates our Terms, policies, or the law. We may respond to law
            enforcement requests and comply with legal obligations. For example,
            if unlawful content is posted on our services, we may enforce our
            Terms and policies accordingly. In some cases, we may use or share
            your information to cooperate with law enforcement, escalate safety
            issues to relevant authorities or industry partners, or fulfill our
            legal duties.
          </li>
        </ol>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          How we share information
        </h2>
        <p className="mb-4">
          To provide our Services to you and our community, we may share
          information with various parties under the following circumstances:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li className="mb-2">
            <strong>Circle Participants:</strong> When you create a circle, we
            share information about the connected account to provide insights
            about its usage, such as who your circle are on CityMoshi. We do not
            share message content.
          </li>
          <li className="mb-2">
            <strong>Public:</strong> While most features on CityMoshi are
            private and for Friends only, we offer public features that allow
            you to opt-in to showcase your best memories to the world or share
            your opinion through public chats. When you use these features, you
            may be discoverable outside of CityMoshi, for example, on the web.
            Some information, like your username, is visible to the public.
          </li>
          <li className="mb-2">
            <strong>Third-Party Apps:</strong> If you choose to connect your
            CityMoshi account with a third-party app, we will share the
            information you direct us to share with that app.
          </li>
          <li className="mb-2">
            <strong>Service Providers:</strong> We share information about your
            activity with our service providers who process that information on
            our behalf. For instance, we rely on service providers to facilitate
            payments or measure and optimize ad performance. We do not share
            private communications with them.
          </li>
          <li className="mb-2">
            <strong>Anti-Fraud Partners:</strong> We share information about
            your activity, such as device and usage information, with industry
            partners working to prevent fraud.
          </li>
          <li className="mb-2">
            <strong>Legal, Safety, and Security Partners:</strong> We share
            information about your activity for the following legal, safety, and
            security reasons:
          </li>
          <ul className="list-disc ml-6 mb-4">
            <li className="mb-2">
              Comply with any valid legal process, governmental request, or
              applicable law, rule, or regulation.
            </li>
            <li className="mb-2">
              Investigate, remedy, or enforce potential Terms of Service and
              Community Guidelines violations.
            </li>
            <li className="mb-2">
              Protect the rights, property, or safety of us, our users, or
              others.
            </li>
            <li className="mb-2">
              Detect and resolve any fraud or security concerns.
            </li>
          </ul>
          <li className="mb-2">
            <strong>Affiliates:</strong> We may share your information with our
            internal subsidiaries as needed to carry out our Services.
          </li>
          <li className="mb-2">
            <strong>For the Purposes of a Merger or Acquisition:</strong> If we
            were to sell or negotiate to sell our business to a buyer or
            potential buyer, we may transfer your personal information to a
            successor or affiliate as part of that transaction.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Integrated Partners</h2>
        <p className="mb-4">
          Our Services may include content and integrations provided by our
          integrated partners. When you interact with these integrations, you
          may be sharing information with both the integrated partner and
          CityMoshi. We are not responsible for how these partners collect or
          use your information. We strongly encourage you to review the privacy
          policies of every third-party service you visit or use, including
          those you interact with through our Services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          International Data Transfers
        </h2>
        <p className="mb-4">
          Our Services connect you with people and events around the world. To
          facilitate this, we may collect your personal information from,
          transfer it to, and store and process it in Rwanda or other countries
          outside your home country. Whenever we transfer information
          internationally, we ensure that safeguards are in place to protect
          your data as required by the laws in your country.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          How Long We Keep Your Information
        </h2>
        <h3 className="text-xl font-semibold mb-2 text-gray">
          General Retention Policy
        </h3>
        <p className="mb-4">
          We generally keep information as long as you instruct us to, as long
          as needed to provide our Services, or as required by law. For example,
          if you store something in Memories, we will keep it until you choose
          to delete it. For Chats in a private or public chat, our systems are
          designed to delete Chats you send within 24 hours after the event
          ends. However, the retention of Chats may vary based on your settings.
          The specific duration we retain your data depends on the feature, your
          settings, and how you use the Services.
        </p>
        <p className="mb-4">
          Here are some factors we consider when deciding how long to keep your
          information:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li className="mb-2">
            <strong>Service Operation:</strong> We store essential account
            details (e.g., name, phone number, email address) to maintain your
            account.
          </li>
          <li className="mb-2">
            <strong>Nature of Information:</strong> Location data may be stored
            for varying lengths depending on its precision and the Services you
            use.
          </li>
          <li className="mb-2">
            <strong>Legal Compliance:</strong> We retain information as needed
            to comply with legal obligations.
          </li>
          <li className="mb-2">
            <strong>Legitimate Purposes:</strong> Information may be retained to
            prevent harm, investigate potential violations of our Terms of
            Service or other policies, respond to reports of abuse, or protect
            ourselves and others.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Automatic Deletion and Legal Exceptions
        </h2>
        <p className="mb-4">
          Although our systems are designed to automatically delete certain
          types of information, we cannot guarantee that deletion will occur
          within a specific timeframe. Legal requirements may compel us to
          retain data, preventing its deletion. For instance, if we receive a
          court order to preserve your content, or if there are reports of abuse
          or policy violations associated with your account or content.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Backup and Legal Obligations
        </h2>
        <p className="mb-4">
          We may also retain certain information in backup for a limited period
          or as mandated by law. For example, if your account or content is
          flagged for abuse or policy violations, we may need to keep relevant
          data until the issue is resolved.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our audience</h2>
        <p className="mb-4">
          Our Services are not intended for children under the age of 16. You
          must confirm that you are 16 years or older to create an account and
          use our Services. If we become aware that you are under 16 (or under
          the minimum age required to use our Services in your jurisdiction
          without parental consent, if higher), we will cease providing Services
          to you and delete your account and data.
        </p>
        <p className="mb-4">
          Additionally, we may limit the collection, use, and storage of
          information for users under 18. As a result, certain functionalities
          may not be available to users under 18.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Updates to the Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. When we make
          changes, we will notify you in one way or another. This could include
          updating the date at the top of the Privacy Policy on our website and
          mobile application. Additionally, we might provide further notice,
          such as adding a statement to our website's homepage or sending you an
          in-app notification.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
