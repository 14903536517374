import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../utils/utils";
import { RiVipCrownFill } from "react-icons/ri";
import { FaCompass, FaSearch, FaComments, FaLock } from "react-icons/fa";

interface Feature {
  icon: React.ReactNode;
  title: string;
  content: string;
  image: string;
  keywords: string[];
}

const features: Feature[] = [
  {
    icon: <RiVipCrownFill className="w-5 h-5" />,
    title: "Personalization",
    content:
      "Receive event recommendations that match your preferences and moods.",
    image: "/images/welcome.png",
    keywords: [
      "AI-Driven",
      "User Preferences",
      "Mood Detection",
      "Custom Experiences",
    ],
  },
  {
    icon: <FaCompass className="w-5 h-5" />,
    title: "Explore",
    content: "Discover all events in one easy-to-use app.",
    image: "/images/home.png",
    keywords: [
      "Event Discovery",
      "Intuitive UI",
      "Category Filtering",
      "Location-Based",
    ],
  },
  {
    icon: <FaSearch className="w-5 h-5" />,
    title: "Hassle-Free",
    content: "Get all the event details you need, effortlessly.",
    image: "/images/details.png",
    keywords: [
      "Quick Info",
      "Ticket Purchasing",
      "Venue Details",
      "Event Schedule",
    ],
  },
  {
    icon: <FaComments className="w-5 h-5" />,
    title: "Join Chats",
    content: "Connect with others who share your vibe.",
    image: "/images/chat-screen.png",
    keywords: [
      "Group Chats",
      "Event Discussion",
      "Meet New People",
      "Shared Interests",
    ],
  },
  {
    icon: <FaLock className="w-5 h-5" />,
    title: "Exclusivity",
    content: "Host private events and keep your special moments alive.",
    image: "/images/create-events.png",
    keywords: [
      "Private Events",
      "Invite-Only",
      "Customizable Settings",
      "Memories Archive",
    ],
  },
];

function Features() {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || !stickyRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const stickyRect = stickyRef.current.getBoundingClientRect();
      const containerTop = containerRect.top;
      const containerBottom = containerRect.bottom;
      const viewportHeight = window.innerHeight;

      // Calculate the progress within the container
      let progress =
        (viewportHeight - containerTop) / (containerBottom - containerTop);

      if (progress < 1/2) progress = 0;
      else progress = (progress - 1/2) / (1/2);

      const clampedProgress = Math.max(0, Math.min(1, progress));

      // Update active index based on scroll position
      const newIndex = Math.floor(clampedProgress * features.length);
      if (
        newIndex >= 0 &&
        newIndex < features.length &&
        newIndex !== activeIndex
      ) {
        setActiveIndex(newIndex);
      }

      // Apply sticky behavior
      if (containerTop <= 0 && containerBottom >= viewportHeight) {
        stickyRef.current.style.position = "fixed";
        stickyRef.current.style.top = "0";
        stickyRef.current.style.left = "0";
        stickyRef.current.style.right = "0";
      } else if (containerBottom < viewportHeight) {
        // When scrolling past the container, stick to the bottom
        stickyRef.current.style.position = "absolute";
        stickyRef.current.style.top = "auto";
        stickyRef.current.style.bottom = "0";
        stickyRef.current.style.left = "0";
        stickyRef.current.style.right = "0";
      } else {
        // When above the container, reset to relative positioning
        stickyRef.current.style.position = "relative";
        stickyRef.current.style.top = "0";
        stickyRef.current.style.bottom = "auto";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeIndex]);

  return (
    <div
      id="features"
      ref={containerRef}
      className="hidden sm:block bg-[#E6F1F4] relative scroll-smooth"
      style={{ height: `200vh` }}
    >
      <div ref={stickyRef} className="transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-[5vh]">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 1 }}
            className="text-center h-[15vh]"
          >
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900">
              App Features
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
              Discover what makes our app unique and powerful.
            </p>
          </motion.div>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 relative h-[75vh]">
              {features.map((feature, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: index === activeIndex ? 1 : 0 }}
                transition={{ duration: 0.5 }}
                className="w-full h-full absolute top-0 left-0"
              >
                <div className="relative w-full h-full">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full h-full object-contain"
                  />
                  <div
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
                    style={{
                      width: '80%',
                      height: '30px',
                      background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 70%)',
                      filter: 'blur(5px)',
                      zIndex: -1,
                      // marginTop: '80px',
                    }}
                  />
                </div>
              </motion.div>
              ))}
            </div>
            <div className="w-full md:w-1/2 md:pl-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={false}
                  animate={{
                    height: index === activeIndex ? "auto" : "60px",
                    opacity: index === activeIndex ? 1 : 0.7,
                  }}
                  className={cn(
                    "mb-4 overflow-hidden rounded-lg",
                    index === activeIndex
                      ? "bg-secondary text-white"
                      : "bg-white text-gray-900 hover:bg-gray-100"
                  )}
                >
                  <div
                    className={cn(
                      "flex items-center gap-3 p-3 cursor-pointer transition-colors"
                    )}
                  >
                    <div className="w-10 h-10 flex items-center justify-center rounded-full bg-white text-secondary">
                      {feature.icon}
                    </div>
                    <h3 className="text-xl font-bold">{feature.title}</h3>
                  </div>
                  {index === activeIndex && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mt-4 px-3"
                    >
                      <p className="mb-4 text-gray-700">{feature.content}</p>
  
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
