import { Mail } from "lucide-react";

function Footer() {
  return (
    <div className="mx-auto w-full max-w-screen-xl px-6 md:px-20 mt-10">
      <div className="flex flex-col md:flex-row items-start justify-between gap-10">
        <div className="flex flex-col gap-3.5">
          <h1 className="font-bold text-xl md:text-2xl text-white font-lato">
            CityMoshi
          </h1>
          <p className="font-medium font-lato text-white md:text-grayLight text-xs md:text-sm max-w-md">
            Our mission is to transform how you discover, plan, and engage with
            events. Here's why CityMoshi is your go-to destination for all
            things events.
          </p>
        </div>
        <div className="flex items-start gap-20">
          {/* <div className="hidden md:flex flex-col justify-start gap-5">
            <h2 className="text-sm font-bold text-white font-lato">
              Navigation
            </h2>
            <ul className="m-0 p-0">
              <li className="text-sm text-grayLight">
                <a href="#welcome" className="hover:underline text-grayLight">
                  Welcome
                </a>
              </li>
              <li className="text-sm text-grayLight">
                <a href="#about" className="hover:underline text-grayLight">
                  About
                </a>
              </li>
              <li className="text-sm text-grayLight">
                <a href="#features" className="hover:underline text-grayLight">
                  Highlights
                </a>
              </li>
              <li className="text-sm text-grayLight">
                <a href="#events" className="hover:underline text-grayLight">
                What's on
                </a>
              </li>
            </ul>
          </div> */}
          <div className="flex flex-col justify-start gap-5">
            <h2 className="hidden md:block text-sm font-bold text-white font-lato">
              Contact Us
            </h2>
            <h2 className="block md:hidden text-sm font-bold text-white font-lato">
              Contact Us
            </h2>
            <ul className="m-0 p-0 flex flex-col gap-5 md:gap-1.5">
              {/* <li className="text-sm text-grayLight flex items-center gap-3 font-medium">
                <Phone size={20} className="text-secondary" />
                +1 234 567 890
              </li> */}
              <a href="mailto:info@citymoshi.com">
                <li className="text-sm text-grayLight flex items-center gap-3 font-medium">
                  <Mail size={20} className="text-secondary" />
                  info@citymoshi.com
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>

      {/* footer for web */}
      <div className="hidden md:flex items-center justify-between md:border-t border-[#64748B] mt-5 py-5">
        <div className="flex items-center gap-10">
          <a
            href="/privacy-policy"
            className="text-sm font-lato text-grayLight capitalize hover:underline"
          >
            Privacy Policy
          </a>
          <a
            href="/terms-of-service"
            className="text-sm font-lato text-grayLight capitalize hover:underline"
          >
            Terms of Service
          </a>
          {/* <a href="/" className="text-sm font-lato text-grayLight capitalize hover:underline">
            Security
          </a> */}
        </div>
        <h3 className="font-medium text-grayLight text-sm">
          All rights reserved
        </h3>
        {/* <div className="flex items-center gap-5">
          <div className="bg-[#A6425C33] rounded-full">
            <img src="/images/linkedin.png" alt="linked in" className="w-5 h-5 m-1.5" />
          </div>
          <div className="bg-[#A6425C33] rounded-full">
            <img src="/images/instagram.png" alt="instagram" className="w-5 h-5 m-1.5" />
          </div>
          <div className="bg-[#A6425C33] rounded-full">
            <img src="/images/facebook.png" alt="facebook" className="w-5 h-5 m-1.5" />
          </div>
        </div> */}
      </div>

      {/* footer for mobile */}
      <div className="flex md:hidden flex-col items-center justify-center mt-10 py-5 gap-5">
        {/* <div className="flex items-center gap-5">
          <div className="bg-[#A6425C33] rounded-full">
            <img
              src="/images/linkedin.png"
              alt="linked in"
              className="w-5 h-5 m-1.5"
            />
          </div>
          <div className="bg-[#A6425C33] rounded-full">
            <img
              src="/images/instagram.png"
              alt="instagram"
              className="w-5 h-5 m-1.5"
            />
          </div>
          <div className="bg-[#A6425C33] rounded-full">
            <img
              src="/images/facebook.png"
              alt="facebook"
              className="w-5 h-5 m-1.5"
            />
          </div>
        </div> */}
        <div className="w-full h-[1px] bg-[#64748B]" />
        <div className="flex items-center gap-10">
          <a
            href="/privacy-policy"
            className="text-sm font-lato text-grayLight capitalize hover:underline"
          >
            Privacy Policy
          </a>
          <a
            href="/terms-of-service"
            className="text-sm font-lato text-grayLight capitalize hover:underline"
          >
            Terms of Service
          </a>
          {/* <a href="/" className="text-sm font-lato text-grayLight capitalize hover:underline">
            Security
          </a> */}
        </div>
        <h3 className="font-medium text-grayLight text-sm">
          All rights reserved
        </h3>
      </div>
    </div>
  );
}

export default Footer;
