import React from "react";
import Download from "./Download";
import EventSlider from "./EventSlider";
import { IoLogoApple, IoLogoGooglePlaystore } from "react-icons/io5";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../constants";

function Events() {
  return (
    <div className="relative px-6 pt-14 md:p-10 z-60 bg-BG">
      <Download />
      <EventSlider />
      <div className="hidden md:block w-full absolute top-1/3 left-3/4">
        <img
          src="/images/dots.png"
          alt="city"
          className="w-96 h-96 rounded-lg object-cover"
        />
      </div>
      <div className="mx-auto w-full max-w-screen-xl md:px-10 lg:px-20 mt-10">
        <div className="flex flex-col lg:flex-row md:items-center justify-between bg-white w-full rounded-xl px-5 md:px-10 lg:px-20 py-7 md:py-10 gap-2.5">
          <h1 className="font-extrabold text-xl leading-7 md:text-4xl text-secondary">
            Download CityMoshi Now!
          </h1>
          <div className="flex items-center justify-center gap-5 md:gap-10">
            <button
              className="w-full md:max-w-max h-full bg-secondary flex items-center gap-1 md:gap-3 cursor-pointer text-white py-2.5 md:py-3 px-1 md:px-4 rounded-lg outline-none m-0 p-0"
              onClick={() => window.open(APP_STORE_LINK, "_blank")}
            >
              <IoLogoApple className="w-8 h-8 text-white" />
              <div className="flex flex-col justify-start items-start md:leading-3 text-start">
                <p className="text-white text-xs m-0 p-0">Download on the</p>
                <p className="text-white text-xs md:text-base font-medium m-0 p-0 leading-3">
                  App Store
                </p>
              </div>
            </button>
            <button
              className="w-full md:max-w-max bg-secondary cursor-pointer flex items-center gap-1 md:gap-3 text-white py-2.5 md:py-3 px-1 md:px-4 rounded-lg outline-none m-0 p-0"
              onClick={() => window.open(GOOGLE_PLAY_LINK, "_blank")}
            >
              <IoLogoGooglePlaystore className="w-8 h-8 text-white" />
              <div className="flex flex-col justify-start items-start md:leading-3 text-start">
                <p className="text-white text-xs m-0 p-0">Download it on</p>
                <p className="text-white text-xs md:text-base font-medium m-0 leading-3">
                  Google Play
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
