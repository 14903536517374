import "../styles/terms-of-service.css";

function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md m-6">
      <h1 className="text-3xl font-bold mb-4">CityMoshi Terms of Service</h1>
      <p className="text-sm font-semibold text-gray mb-6">
        Effective: 27 February 2024
      </p>

      <h2 className="text-2xl font-semibold mb-4">Welcome to CityMoshi</h2>
      <p className="mb-6 text-gray">
        These Terms of Service ("Terms") govern your use of CityMoshi services.
        Our Services are personalized, and these Terms, along with our Privacy
        Policy and Community Guidelines, explain their operation. By creating a
        CityMoshi account or using CityMoshi, you agree to these Terms, which
        constitute a legally binding contract between you and CityMoshi. Please
        read them carefully.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        CityMoshi's Services and Role
      </h2>

      <h3 className="text-xl font-semibold mb-2 text-gray">Our Offerings</h3>
      <p className="mb-4 text-gray">
        CityMoshi is a platform dedicated to helping users discover and attend a
        wide array of get-togethers, meet-ups, and events around them. Our
        mission is to promote tourism and hospitality by connecting people with
        live, in-person experiences in various locations worldwide. Through our
        platform, we empower users to explore, create, and participate in events
        and rendezvous that enhance their social interactions and cultural
        experiences, fostering a sense of community and adventure across the
        globe.
      </p>
      <p className="mb-6 text-gray">
        Our services allow organizers to post events, respond to client
        inquiries, and interact directly with users. Users can explore
        activities based on their location and preferences, create private
        social events with their circles, and store and share memories through
        photos and videos.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">Our Position</h3>
      <p className="mb-4 text-gray">
        We do not create, organize, or own the events listed on our Services,
        nor do we sell tickets, registrations, or merchandise. Our role is to
        facilitate event promotion through our platform.
      </p>
      <p className="mb-4 text-gray">
        Organizers are solely responsible for ensuring their events and event
        pages comply with all applicable laws, rules, and regulations, and for
        delivering goods and services as described.
      </p>
      <p className="mb-6 text-gray">
        For paid ticket events, Organizers choose the payment method based on
        their location, and consumers must use the selected payment processing
        method to complete payments.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Your Authority to Bind an Entity
      </h3>
      <p className="mb-6 text-gray">
        If you use our Services on behalf of an entity (such as your employer),
        you agree to these Terms on behalf of that entity and its affiliates.
        You confirm that you have the authority to bind the entity to these
        Terms. In such instances, "you" and "your" refer to both you and the
        entity.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Privacy and Consumer Information
      </h2>

      <h3 className="text-xl font-semibold mb-2 text-gray">Privacy Policy</h3>
      <p className="mb-6 text-gray">
        We handle and protect your personal data in accordance with our Privacy
        Policy. We are dedicated to safeguarding the personal information you
        provide or that we collect through CityMoshi Properties, as outlined in
        our Privacy Policy.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Consumer Information
      </h3>
      <p className="mb-6 text-gray">
        We expect you to adhere to certain rules when managing users'
        information. As an Organizer, you must always comply with all applicable
        local, state, provincial, national, and other laws, rules, and
        regulations concerning the information you collect from or receive about
        Consumers or other individuals. Additionally, you must adhere to any
        relevant policies we post on the Services regarding the information you
        collect from or receive about Consumers.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Terms & Termination</h2>

      <h3 className="text-xl font-semibold mb-2 text-gray">Terms</h3>
      <p className="mb-6 text-gray">
        These Terms apply as soon as you access our Services by any means and
        will remain in effect until terminated. Either you or CityMoshi may
        decide to end the relationship as detailed in Sections 4.2 and 4.3. Upon
        termination, these Terms will generally no longer apply, except for
        certain provisions that will always remain in effect, as described in
        Section 4.4.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">Termination</h3>
      <p className="mb-2 text-gray">
        We reserve the right to revoke your access to our Services:
      </p>
      <p className="mb-4 text-gray">
        We may suspend or terminate your use of the Services at any time if:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray">
        <li className="mb-2">
          You violate or breach these Terms or any other agreement with us, or
          fail to pay any due amounts.
        </li>
        <li className="mb-2">
          You misuse or abuse the Services, or use them in an unintended or
          unauthorized manner.
        </li>
        <li className="mb-2">
          Your conduct, on or off the CityMoshi Properties, jeopardizes the
          safety of our community, the integrity of CityMoshi Properties, or
          disrupts the experience of others.
        </li>
        <li className="mb-2">
          Allowing your access would violate applicable laws, rules,
          regulations, or court orders, or expose us to legal liability.
        </li>
      </ul>
      <p className="mb-6 text-gray">
        We may discontinue or modify the Services, or any portion thereof, at
        any time. We will make reasonable efforts to notify you if such
        termination might materially affect you. You agree that we will not be
        liable to you or any third party for terminating your right to use or
        access the Services.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Deleting Your Account
      </h3>
      <p className="mb-6 text-gray">
        If you wish to stop using our Services, you can terminate your access by
        deleting your account, unless otherwise agreed in a separate written
        agreement. If you use the Services without a registered account, you
        must stop accessing the Services to terminate these Terms. These Terms
        will remain in effect as long as you continue to access the Services. If
        a separate agreement governing your use of the Services terminates or
        expires, these Terms will govern your use thereafter.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Survival of Terms
      </h3>
      <p className="mb-6 text-gray">
        Certain provisions of these Terms will continue to apply even after
        termination. These include, but are not limited to, limitations on
        liability, releases, indemnification obligations, disclaimers of
        warranties, choices of law and judicial forum, and intellectual property
        protections and licenses.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Release and Indemnification
      </h2>

      <h3 className="text-xl font-semibold mb-2 text-gray">Release</h3>
      <p className="mb-4 text-gray">
        You agree not to involve us in disputes with any third parties.
      </p>
      <p className="mb-6 text-gray">
        You hereby release us, along with our affiliates, subsidiaries, and our
        and their respective officers, directors, agents, co-branders,
        licensors, payment processing partners, vendors, other partners,
        independent contractors, and employees (collectively, the "CityMoshi
        Released Parties"), from all damages (whether direct, indirect,
        incidental, consequential, or otherwise), losses, liabilities, costs,
        and expenses of any kind, known or unknown, arising out of a dispute
        between you and a third party (including other users) related to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray">
        <li className="mb-2">
          The Services or any event listed on the Services, including events
          created on the CITYMOSHI platform.
        </li>
        <li className="mb-2">
          Your Licensure (as defined below), any failure to obtain or maintain
          any Licensure, or any error in obtaining or maintaining any Licensure
        </li>
        <li className="mb-2">
          Any Feedback (as defined below) that you give or receive; or
        </li>
        <li className="mb-2">
          Your Content or Your intellectual property assets.
        </li>
      </ul>

      <i className="block bg-gray-100 rounded-md mb-6 text-gray">
        Additionally, you waive any applicable law or statute that states, in
        essence: "a general release does not extend to claims that the releasing
        party does not know or suspect to exist in his favor at the time of
        executing the release, which if known by him must have materially
        affected his settlement with the released party."
      </i>

      <h3 className="text-xl font-semibold mb-2 text-gray">Indemnification</h3>
      <p className="mb-6 text-gray font-semibold">
        You agree to defend us if a third party makes a claim against us because
        of your actions or omissions.
      </p>
      <p className="mb-6 text-gray">
        You agree to defend, indemnify, and hold harmless the CityMoshi Released
        Parties from any and all damages (whether direct, indirect, incidental,
        consequential, or otherwise), losses, liabilities, costs, and expenses
        (including reasonable attorneys' and accounting fees) arising from any
        claim, demand, suit, proceeding (whether before an arbitrator, court,
        mediator, or otherwise), or investigation made by any third party (each
        a "Claim") related to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray">
        <li className="mb-2">
          Your breach of these Terms (including any incorporated terms,
          agreements, or policies).
        </li>
        <li className="mb-2">Your unauthorized use of the Services.</li>
        <li className="mb-2">
          Your Licensure, any failure to obtain or maintain any Licensure, or
          any error in obtaining or maintaining any Licensure.
        </li>
        <li className="mb-2">Any Feedback you give or receive.</li>
        <li className="mb-2">
          Your violation of any applicable local, state, provincial, national,
          or other law, rule, or regulation, or the rights of any third party.
        </li>
        <li className="mb-2">Our collection and remission of taxes.</li>
        <li className="mb-2">
          If you are an Organizer, Your Content and Your intellectual property
          assets, provided that this indemnification does not apply if the Claim
          arises from CityMoshi's gross negligence or willful misconduct.
        </li>
      </ul>
      <p className="mb-6 text-gray">
        We will notify you of any such Claim. Our failure or delay in providing
        notice will not limit your indemnification obligations unless you are
        materially prejudiced by the delay. In certain situations, we may choose
        to handle the Claim ourselves, and you agree to cooperate with us as
        needed.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Disclaimer of Warranties and Assumption of Risks by You
      </h2>

      <h3 className="text-xl font-semibold mb-2 text-gray">Disclaimers</h3>
      <p className="mb-6 text-gray">
        We aim to provide the best Services, but there are things we can't
        guarantee.
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray">
        <li className="mb-2">
          Our Services are provided "as is" and "as available," meaning we do
          not promise that they will always meet your needs. Specifically, we do
          not guarantee that:
        </li>
        <ul className="list-disc pl-6 mb-6 text-gray">
          <li className="mb-2">
            The Services will meet your requirements or expectations.
          </li>
          <li className="mb-2">
            The Services will always be uninterrupted, timely, secure, or
            error-free.
          </li>
          <li className="mb-2">
            The results from using the Services will be accurate or reliable.
          </li>
        </ul>
        <li className="mb-2">We do not control and do not guarantee:</li>
        <ul className="list-disc pl-6 mb-6 text-gray">
          <li className="mb-2">
            The quality, safety, success, accuracy, or legality of any event or
            content related to an event.
          </li>
          <li className="mb-2">
            The accuracy of any information provided by users, including
            feedback and personal information shared with organizers.
          </li>
          <li className="mb-2">
            The ability of any user to complete a transaction.
          </li>
        </ul>
        <li className="mb-2">
          We are not responsible for the actions or omissions of any third
          parties, including those that help provide the Services, those chosen
          by an Organizer to assist with an event, or those you choose to use
          when utilizing the Services.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Assumption of Risks
      </h3>
      <p className="mb-6 text-gray">
        Attending live events involves certain risks, and by participating, you
        voluntarily assume these risks. Some events may pose risks of illness,
        injury, disability, or death, which you willingly accept by choosing to
        participate.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        CityMoshi-Hosted Events
      </h3>
      <p className="mb-6 text-gray">
        Most events on our platform are not hosted by CityMoshi. If you attend
        an event hosted by us, you assume all risks and waive any claims against
        CityMoshi, event producers, presenters, and their insurers for any
        liability, including personal injury, property damage, or wrongful death
        related to the event.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Application of Disclaimers
      </h3>
      <p className="mb-6 text-gray">
        These disclaimers apply as far as the law allows. If any warranties are
        required by law, they will be limited to the shortest duration
        permitted.
      </p>
      <p className="mb-6 text-gray">Who Is Responsible if Something Happens:</p>
      <ul className="list-disc pl-6 mb-6 text-gray">
        <li className="mb-2">
          Our Service is provided "as is," and we cannot guarantee it will
          always be safe, secure, or function perfectly. To the extent permitted
          by law, we disclaim all warranties, express or implied, including
          implied warranties of merchantability, fitness for a particular
          purpose, title, and non-infringement.
        </li>
        <li className="mb-2">
          We do not control what people do or say and are not responsible for
          their actions or content, whether online or offline. This includes
          unlawful or objectionable content. We are also not responsible for
          services and features offered by other people or companies, even if
          accessed through our Service.
        </li>
        <li className="mb-2">
          Our liability for any issues with the Service is limited to the
          fullest extent the law allows. We are not responsible for lost
          profits, revenues, information, or data, or for any consequential,
          special, indirect, exemplary, punitive, or incidental damages arising
          out of or related to these Terms, even if we know they are possible.
          This includes the deletion of your content, information, or account.
        </li>
        <li className="mb-2">Governing Law and Dispute Resolution</li>
      </ul>

      <p className="mb-6 text-gray">
        If you use our services, the laws of your country of residence will
        govern any claim, cause of action, or dispute you have against us
        arising out of or related to these Terms ("claim"). You may resolve your
        claim in any competent court in your country with jurisdiction over the
        claim.
      </p>

      <p className="mb-6 text-gray">
        In all other cases, you agree that claims must be resolved exclusively
        in Rwanda. You submit to the personal jurisdiction of Rwandan courts for
        the purpose of litigating any such claim, and the laws of the Republic
        of Rwanda will govern these Terms and any related claim, without regard
        to conflict of law provisions. Before filing any case against CityMoshi,
        you agree to notify CityMoshi of the claim at least two months prior to
        taking any legal action.
      </p>

      <p className="mb-6 text-gray">
        However, CityMoshi reserves the right to bring any claim against you
        related to efforts to abuse, interfere with, or engage with our products
        in unauthorized ways in your country of residence if it has jurisdiction
        over the claim.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Feedback and Suggestions
      </h3>
      <p className="mb-6 text-gray">
        We appreciate your feedback and suggestions. However, we may use them
        without any restrictions or obligation to compensate you, and we are not
        required to keep them confidential.
      </p>

      <h3 className="text-xl font-semibold mb-2 text-gray">
        Updating These Terms
      </h3>
      <p className="mb-6 text-gray">
        We may update our Service and policies, necessitating changes to these
        Terms to accurately reflect those updates. Unless legally required
        otherwise, we will notify you of any changes (e.g., through our Service)
        and give you an opportunity to review them before they take effect. By
        continuing to use the Service after the changes go into effect, you
        agree to the updated Terms. If you do not agree to these or any updated
        Terms, you can delete your account.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Conclusion</h2>
      <p className="mb-6 text-gray">
        We hope these Terms help you understand your rights and responsibilities
        while using CityMoshi. Thank you for joining our community!
      </p>
    </div>
  );
}

export default TermsOfService;
