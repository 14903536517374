import React from "react";

const DataDeletionPage: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 text-gray">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full">
        <h1 className="text-2xl font-semibold mb-4">Data Deletion Request</h1>
        <p className="mb-4">
          At <span className="font-semibold">CityMoshi</span>, we respect your
          privacy and are committed to protecting your personal data. If you
          wish to delete the data that CityMoshi has collected from your
          Facebook or Google account, please follow the steps below.
        </p>

        <h2 className="text-lg font-medium mb-2">
          Steps to Request Data Deletion:
        </h2>
        <ol className="list-decimal list-inside mb-4">
          <li>
            Log in using your Facebook or Google account linked to your
            CityMoshi account.
          </li>
          <li>
            Visit the CityMoshi Data Deletion Page by clicking on the profile
            icon in the top left corner of the screen and click on your profile
            name. On the profile page, scroll down to the bottom and click on
            "Delete your account". This will take to a page where you can delete
            your data. Or you could follow this link:
            <br />
            <a
              href="https://citymoshi.com/link/delete-account"
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              CityMoshi Data Deletion
            </a>
          </li>
          <li>
            Processing Time:
            <br />
            Your data deletion request will be processed within 30 days. You
            will receive a confirmation email once your data has been deleted.
          </li>
        </ol>

        <h3 className="text-lg font-medium mb-2">For Google Accounts:</h3>
        <p className="mb-4">
          If you wish to delete data collected from your Google account, follow
          the same process outlined above.
        </p>

        <a
          href="/data-deletion"
          className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
        >
          Request Data Deletion
        </a>

        <div className="mt-6">
          <p>
            If you have any questions or concerns about this process, please
            contact our support team at{" "}
            <a
              href="mailto:support@citymoshi.com"
              className="text-blue-500 hover:underline"
            >
              support@citymoshi.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataDeletionPage;
