import React from "react";
import { cn } from "../utils/utils";
import { RiVipCrownFill } from "react-icons/ri";
import { FaCompass, FaSearch, FaComments, FaLock } from "react-icons/fa";

const collapsibles = [
    {
      icon: <RiVipCrownFill className="w-5 h-5" />,
      title: "Personalization",
      content:
        "Receive event recommendations that match your preferences and moods.",
      image: "/images/welcome.png",
      keywords: [
        "AI-Driven",
        "User Preferences",
        "Mood Detection",
        "Custom Experiences",
      ],
    },
    {
      icon: <FaCompass className="w-5 h-5" />,
      title: "Explore",
      content: "Discover all events in one easy-to-use app.",
      image: "/images/home.png",
      keywords: [
        "Event Discovery",
        "Intuitive UI",
        "Category Filtering",
        "Location-Based",
      ],
    },
    {
      icon: <FaSearch className="w-5 h-5" />,
      title: "Hassle-Free",
      content: "Get all the event details you need, effortlessly.",
      image: "/images/details.png",
      keywords: [
        "Quick Info",
        "Ticket Purchasing",
        "Venue Details",
        "Event Schedule",
      ],
    },
    {
      icon: <FaComments className="w-5 h-5" />,
      title: "Join Chats",
      content: "Connect with others who share your vibe.",
      image: "/images/chat-screen.png",
      keywords: [
        "Group Chats",
        "Event Discussion",
        "Meet New People",
        "Shared Interests",
      ],
    },
    {
      icon: <FaLock className="w-5 h-5" />,
      title: "Exclusivity",
      content: "Host private events and keep your special moments alive.",
      image: "/images/create-events.png",
      keywords: [
        "Private Events",
        "Invite-Only",
        "Customizable Settings",
        "Memories Archive",
      ],
    },
  ];

function FeaturesMobile() {
  const [openedCollapsible, setOpenedCollapsible] = React.useState<
    number | null
  >(1);
  const [selectedImage, setSelectedImage] = React.useState<string>(
    collapsibles[0].image
  );
  return (
    <div className="block sm:hidden w-full bg-[#E6F1F4] flex flex-col gap-10 items-center py-14 md:py-20" id="highlights">
      <div className="flex flex-col gap-5 items-center">
        <h1 className="text-3xl md:text-4xl font-extrabold font-inter text-black text-center capitalize">
          App Features
        </h1>
        <p className="text-grayLight max-w-xs md:max-w-2xl font-inter text-center leading-5 text-xs md:text-base">
          Simplified features for your smooth event discovery and experience!
          {/* Welcome to CityMoshi, where event empowerment meets simplicity. Our
          innovative features are designed with you in mind, ensuring a seamless
          and secure event experience. */}
        </p>
      </div>
      <div className="mx-auto w-full max-w-screen-xl h-auto gap-5 md:px-20 flex flex-col md:flex-row items-start mb-10">
        <img
          src={selectedImage}
          alt="iphone"
          className="h-[400px] mx-auto scale-110 md:scale-125 md:translate-y-10 mb-5"
        />
        <div className="flex flex-col gap-5 px-5 w-full">
          {collapsibles.map((collapsible, index) => (
            <div
              key={index}
              className={cn(
                "w-full bg-white rounded-lg p-5 flex flex-col gap-5 cursor-pointer delay-75 transition-all duration-300 ease-in-out",
                openedCollapsible === index
                  ? "bg-secondary"
                  : "bg-white shadow-sm"
              )}
              onClick={() => {
                if (openedCollapsible === index) {
                  setOpenedCollapsible(null);
                } else {
                  setOpenedCollapsible(index);
                }
                setSelectedImage(collapsible.image);
              }}
            >
              <div className="flex items-center gap-3.5">
                <div
                  className={cn(
                    "w-3.5 md:w-8 h-3.5 md:h-8 flex items-center justify-center rounded-full",
                    openedCollapsible === index ? "text-white" : "text-black"
                  )}
                >
                  {collapsible.icon}
                </div>
                <h3
                  className={cn(
                    "text-xs md:text-xl font-bold font-lato text-black",
                    openedCollapsible === index ? "text-white" : "text-black"
                  )}
                >
                  {collapsible.title}
                </h3>
              </div>
              {openedCollapsible === index && (
                <p
                  className={cn(
                    "font-lato font-xs md:font-base",
                    openedCollapsible === index ? "text-white" : "text-black"
                  )}
                >
                  {collapsible.content}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturesMobile;