import { Menu, X } from "lucide-react";
import React from "react";
import { cn } from "../utils/utils";

function MobileHeader() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="px-3 z-50">
      <div
        className={cn(
          "fixed top-0 left-0 w-full max-w-screen-xl px-2.5",
          isOpen ? "rounded-b-none" : "rounded-b-xl"
        )}
      >
        <div className="w-full relative  flex md:hidden items-center justify-between gap-3 h-16 rounded-xl bg-white mt-7 px-2.5">
          <div className="flex items-center gap-0.5">
            <div className="bg-white rounded-md">
              <img
                src="/images/logo/mobile-logo.png"
                alt="Citymoshi Logo"
                className="w-7 h-7 m-1"
              />
            </div>
            <h1 className="font-medium text-BG font-inter text-lg">
              CityMoshi
            </h1>
          </div>
          <a
            target="_blank"
            href="https://dashboard.citymoshi.com"
            className="bg-secondary text-white font-bold py-1.5 px-3 rounded outline-none flex items-center gap-1 hover:bg-secondary/80 cursor-pointer ml-auto"
            type="button" rel="noreferrer"
          >
            Post Events
            <img
              src="/images/arrow-up-right.png"
              alt="Download"
              className="w-5 h-5"
            />
          </a>
          <button
            type="button"
            className="block outline-none p-0 m-0 border-none cursor-pointer"
          >
            {isOpen ? (
              <X
                size="24"
                className="text-black"
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <Menu
                size="24"
                className="text-black"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </button>
          <div
            className={cn(
              "absolute top-14 left-0 w-full bg-white rounded-b-xl z-50 px-2.5 animate-fade-in-down transition-all duration-300 ease-in-out overflow-hidden shadow-md border border-gray-200",
              !isOpen && "hidden"
            )}
          >
            <div className="w-full">
              {/* <a
                href="#welcome"
                className="flex items-center justify-center mt-2.5 text-black h-16 text-xl font-bold font-lato w-full text-center"
              >
                Welcome
              </a> */}
              <a
                href="#about"
                onClick={() => setIsOpen(false)}
                className="flex items-center justify-center mt-2.5 text-black h-16 text-xl font-bold font-lato w-full text-center"
              >
                About
              </a>
              <a
                href="#highlights"
                onClick={() => setIsOpen(false)}
                className="flex items-center justify-center mt-2.5 text-black h-16 text-xl font-bold font-lato w-full text-center"
              >
                Highlights
              </a>
              <a
                href="#events"
                onClick={() => setIsOpen(false)}
                className="flex items-center justify-center mt-2.5 text-black h-16 text-xl font-bold font-lato w-full text-center"
              >
                What{`'`}s On
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
