import { useEffect } from "react";
import Header from "../components/Header";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Features from "../components/Features";
import Events from "../components/Events";
import Footer from "../components/Footer";
import MobileHeader from "../components/MobileHeader";
import FeaturesMobile from "../components/Features-mobile";
import { API_URL } from "../constants";

function Home() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qrId = urlParams.get('qr');

    if (qrId) {
      fetch(`${API_URL}/qr/scan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qrId }),
      })
        .then(() => console.log('QR Scan Recorded'))
        .catch((error) => console.error('QR Scan Error:', error))
        .finally(() => {
          urlParams.delete('qr');
          const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
          window.history.replaceState({}, '', newUrl);
        });
    }
  }, []);

  return (
    <div>
      <Header />
      <Welcome />
      <About />
      <Features />
      <FeaturesMobile />
      <Events />
      <Footer />
      <MobileHeader />
    </div>
  );
}

export default Home;
